var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('CommonAlertDialog',{attrs:{"show":_vm.showAlert,"item":_vm.alertItem},on:{"onCloseAlert":_vm.onCloseAlert}}),_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-clipboard-text","title":_vm.$t('invoice.list')}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":_vm.footerProps,"multi-sort":"","item-key":"customer_id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.need_invoice",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("Translate")(_vm._f("getText")(item.need_invoice,_vm.invoice_items))))]}},{key:"item.export",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{staticClass:"mr-3",attrs:{"small":""},on:{"click":function($event){return _vm.exportBalanceSheet(item)}}},[_vm._v(" "+_vm._s(_vm.$t("balance_list"))+" ")])]}}],null,true)}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{staticClass:"mr-3",attrs:{"small":""},on:{"click":function($event){return _vm.exportCargoList(item)}}},[_vm._v(" "+_vm._s(_vm.$t("cargo_handling_list"))+" ")])]}}],null,true)}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{staticClass:"mr-3",attrs:{"small":""},on:{"click":function($event){return _vm.exportCargoSplitList(item)}}},[_vm._v(" "+_vm._s(_vm.$t("cargo_handling_split_list"))+" ")])]}}],null,true)})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"large":""},on:{"click":function($event){return _vm.viewItem(item)}}},on),[_vm._v("mdi-eye")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("view")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"large":""},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("disable")))])])]}}])})],1),_c('InvoiceContainer',{ref:"containerList",attrs:{"invoice_group_items":_vm.invoice_group_items},on:{"refresh":_vm.refresh}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }