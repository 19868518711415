<template>
  <v-container fluid>
    <CommonAlertDialog
      :show="showAlert"
      :item="alertItem"
      v-on:onCloseAlert="onCloseAlert"
    >
    </CommonAlertDialog>
    <!-- <v-row>
      <v-col class="text-right">
        <v-btn
          color="primary"
          dark
          class="mb-2 mr-2"
          @click="exportBalanceSheet"
        >
          <v-icon>mdi-file-excel</v-icon>
          {{ $t("balance_list") }}
        </v-btn>
      </v-col>
    </v-row> -->
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('invoice.list')"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        multi-sort
        item-key="customer_id"
      >
        <template v-slot:item.need_invoice="{ item }">{{ 
            item.need_invoice | getText(invoice_items) | Translate
        }}</template>
        <template v-slot:item.export="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                small
                class="mr-3"
                @click="exportBalanceSheet(item)"
              >
                {{ $t("balance_list") }}
              </v-btn>
            </template>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                small
                class="mr-3"
                @click="exportCargoList(item)"
              >
                {{ $t("cargo_handling_list") }}
              </v-btn>
            </template>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                small
                class="mr-3"
                @click="exportCargoSplitList(item)"
              >
                {{ $t("cargo_handling_split_list") }}
              </v-btn>
            </template>
          </v-tooltip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                large
                class="mr-2"
                v-on="on"
                @click="viewItem(item)"
              >mdi-eye</v-icon>
            </template>
            <span>{{ $t("view") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                large
                class="mr-2"
                v-on="on"
                @click="deleteItem(item)"
              >mdi-delete</v-icon>
            </template>
            <span>{{ $t("disable") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
    <InvoiceContainer
      :invoice_group_items="invoice_group_items"
      v-on:refresh="refresh"
      ref="containerList"
    ></InvoiceContainer>
  </v-container>
</template>
<script>
import CommonAlertDialog from "../dialogs/CommonAlertDialog";
import InvoiceFilter from "../filters/InvoiceFilter";
import InvoiceContainer from "./InvoiceContainerList";
import BaseTable from "./BaseTable.vue";
import { invoice_items } from "@/definition.js";
export default {
  data: (vm) => ({
    url: "/container/invoice_group/",
    headers: [
      {
        sortable: true,
        text: vm.$i18n.t("id"),
        value: "id",
      },
      {
        sortable: true,
        text: vm.$i18n.t("name"),
        value: "name",
      },
      {
        sortable: false,
        text: vm.$i18n.t("export"),
        value: "export",
      },
      {
        sortable: false,
        text: vm.$i18n.t("actions"),
        value: "actions",
        width: "10%",
      },
    ],
    expand_headers: [
      {
        sortable: true,
        text: vm.$i18n.t("invoice.customer"),
        value: "customer",
        // width: "5%",
      },
      {
        sortable: true,
        text: vm.$i18n.t("invoice.customer"),
        value: "commodity_per_box",
        // width: "5%",
      },
    ],
    items: [],
    options: {},
    totalItems: 0,
    loading: false,
    footerProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: "mdi-chevron-double-left",
      lastIcon: "mdi-chevron-double-right",
      prevIcon: "mdi-chevron-left",
      nextIcon: "mdi-chevron-right",
      itemsPerPageOptions: [20, 50, 100],
    },
    invoice_group_items: [],
    invoice_items: invoice_items,
    undispatch_items: [],
    expanded: [],
    invoice_group: null,
  }),
  mixins: [BaseTable],
  components: {
    InvoiceFilter,
    CommonAlertDialog,
    InvoiceContainer,
  },
  methods: {
    // setInvoiceGroupItems(data) {
    //   this.setListItems(data.results, this.invoice_group_items, "name");
    //   console.log("this.invoice_group_items", this.invoice_group_items);
    // },
    setResult(data) {
      this.totalItems = data.count;
      this.items = data.results;
      this.undispatch_items = data.undispatch_datas;
      this.invoice_group = data.invoice_group;
      this.setListItems(data.results, this.invoice_group_items, "name");
    },
    editItem(item) {
      this.dialogItem = item;
      this.showDialog = true;
    },
    viewItem(item) {
      this.$router.push({
        name: "InvoiceDetail",
        query: {
          invoice_group: item.id,
          name: item.name,
        },
      });
    },
    setInvoiceCustomer(data) {
      console.log("setInvoiceCustomer", data);
      //call api
      const url = "/container/invoice_group/dispatchInvoice/";
      this.postApi(data, url);
    },
    exportBalanceSheet(item) {
      console.log("exportBalanceSheet", item);
      // 匯出對帳單
      const url = "/container/container/getBalanceSheets/";
      this.postFileApi({ invoice_group: item.id }, url, "對帳單_"+item.name);
    },
    exportCargoList(item){
      // 匯出理貨單
      const url = "/container/invoice_group/"+item.id+"/getCargoList/";
      // this.getApi(url,this.exportCargoSplitList);
      this.getFileApi(url,"理貨表");
    },
    exportCargoSplitList(item){
      const url = "/container/invoice_group/"+item.id+"/getCargoSplitList/";
      this.getFileApi(url,"理貨表-拆");
    },
    refresh(){
      this.getApi()
      this.$refs.containerList.getApi()
    },
    deleteItem(item){
      this.postApi({}, "/container/invoice_group/"+item.id+"/unbindInvoice/",this.refresh);
    },
  },
  mounted() {
  },
};
</script>
