<template>
  <v-container>
    <v-row dense>
      <v-col>
        <v-select
          v-model="filterItem.invoice_group"
          :label="$t('invoice.name')"
          :items="invoice_group_items"
          outlined
          dense
          clearable
          :clear-icon-cb="onChange"
          :item-text="(item) => $t(item.text)"
        ></v-select>
      </v-col>
      <v-col class="text-right">
        <v-btn
          color="primary"
          dark
          class="mb-2"
          @click="onChange"
        >
          <v-icon left>mdi-magnify</v-icon>
          {{ $t("search") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import BaseFilter from "./BaseFilter";
export default {
  props: {
    invoice_group_items: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data: (vm) => ({}),
  mixins: [BaseFilter],
  methods: {},
};
</script>