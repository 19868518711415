<template>
  <v-dialog
    v-model="show"
    :max-width="max_width"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-radio-group
                v-model="mode"
                row
              >
                <v-radio
                  label="新增發票"
                  :value="2"
                ></v-radio>  
                <v-radio
                  label="既有發票"
                  :value="1"
                ></v-radio>
                
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="mode==1">
              <v-select
                v-model="invoice_group"
                :label="$t('invoice.list')"
                :items="invoice_group_items"
                :item-text="(item) => $t(item.text)"
              ></v-select>
            </v-col>
            <v-col v-else>
              <v-text-field
                v-model="invoice_name"
                :label="$t('invoice.tip')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close">{{ $t("cancel") }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :disabled="allowSave"
          @click="save"
        >{{ $t("save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseDialog from "./BaseDialog";
export default {
  props: {
    selected: [],
    invoice_group_items: [],
  },
  data: (vm) => ({
    topic: vm.$i18n.t("invoice.dispatch"),
    mode: 2,
    invoice_name: "",
    invoice_group: null,
  }),
  mixins: [BaseDialog],
  computed: {
    allowSave() {
      return false;
    },
  },
  methods: {
    save() {
      console.log("invoice_group", this.invoice_group);
      if (this.mode === 1) {
        this.$emit(
          "onSave",
          this.selected,
          this.mode,
          null,
          this.invoice_group
        );
      } else {
        this.$emit("onSave", this.selected, this.mode, this.invoice_name, null);
        
      }
    },
  },
  mounted() {
    var date = new Date();
    var y = date.getFullYear();
    var m = date.getMonth();
    this.invoice_name = y + "-" + String(m + 1).padStart(2, 0);
  },
};
</script>