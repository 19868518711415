<template>
  <v-dialog
    v-model="show"
    :max-width="max_width"
    @click:outside="closeAlert"
    @keydown.esc="closeAlert"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ alertTitle }}</span>
      </v-card-title>
      <v-card-text>
        {{ $t(parseMessage(item))  }}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="closeAlert">{{ "OK" }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseDialog from "./BaseDialog";
export default {
  data: (vm) => ({
    alertTitle: "",
  }),
  mixins: [BaseDialog],
  methods: {
    closeAlert() {
      this.item = null
      this.$emit("onCloseAlert");
    },
    parseMessage(item) {
      this.alertTitle = this.$t("error");
      if (typeof item && item) {
        console.log(item)
        if (item.status === 403) {
          return "alert.forbidden"
        }else if (item.status === 408) {
          return "alert.timeout"
        }else if (typeof item.data != "undefined") {
          console.log(typeof item.data)
          console.log(item.data)
          if (typeof item.data == 'object') {
            var msg = ""
            for (const [field, errors] of Object.entries(item.data)) {
              msg += "[" + field + "]: " + errors[0] + " "
            }
            return msg
          }
          if (typeof item.data.non_field_errors != "undefined") {
            return JSON.stringify(item.data.non_field_errors);
          } else if (typeof item.data.name != "undefined") {
            return item.data.name[0];
          } else if (typeof item.data == "string") {
            if ('detail' in item) {
              return this.$t("alert." + item.data, item.detail);
            }
            return this.$t("alert." + item.data);
          }else {
            return JSON.stringify(item.data);
          }
        }
      }
    },
  },
};
</script>
