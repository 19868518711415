<template>
  <div>
    <InvoiceBindDialog
      :show="showDialog"
      :selected="selected"
      :invoice_group_items="invoice_group_items"
      v-on:onSave="onSaveEvent"
      v-on:onClose="onCloseEvent"
    ></InvoiceBindDialog>
    <v-row>
      <v-col class="text-right">
        <v-btn
          color="primary"
          dark
          class="mb-2"
          @click="addInvoiceGroup"
        >
          <v-icon>mdi-file-excel</v-icon>
          {{ $t("invoice.dispatch") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-tabs>
      <v-tab @click="invoice_complete=false">{{ $t('invoice.incomplete') }}</v-tab>
      <v-tab @click="invoice_complete=true">{{ $t('invoice.complete') }}</v-tab>
    </v-tabs>
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('container.list')"
      class="py-3"
    >
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        multi-sort
        show-select
      >
        <template v-slot:top="{pagination, options, updateOptions}">
          <v-data-footer 
            :pagination="pagination" 
            :options="options"
            @update:options="updateOptions"
            :show-first-last-page="true"
            :show-current-page="true"
            first-icon="mdi-chevron-double-left"
            last-icon="mdi-chevron-double-right"
            prev-icon="mdi-chevron-left"
            next-icon="mdi-chevron-right"
            :items-per-page-options="[20, 50, 100]"
          />
        </template>
        <template v-slot:item.total_cubic_feet="{ item }">
          {{ fixedFloat(item.total_cubic_feet, 2, 0) }}
        </template>
      </v-data-table>
    </base-material-card>
  </div>
</template>
<script>
import BaseTable from "./BaseTable.vue";
import InvoiceBindDialog from "../dialogs/InvoiceBindDialog";
import { container_status } from "@/definition.js";
export default {
  props: {
    invoice_group_items: [],
  },
  data: (vm) => ({
    url: "/container/container/",
    headers: [
      {
        sortable: true,
        text: vm.$i18n.t("ID"),
        value: "id",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.number"),
        value: "container_number",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.order_number"),
        value: "order_number",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.total_box_amount"),
        value: "total_box_amount",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.total_cubic_feet"),
        value: "total_cubic_feet",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.stuffing-date"),
        value: "stuffing_date",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.cutoff-date"),
        value: "cutoff_date",
      },
      //   {
      //     sortable: false,
      //     text: vm.$i18n.t("export"),
      //     value: "export",
      //   },
      //   {
      //     sortable: false,
      //     text: vm.$i18n.t("actions"),
      //     value: "actions",
      //   },
    ],
    selected: [],
    invoice_complete: false,
    container_status: container_status,
  }),
  mixins: [BaseTable],
  components: {
    InvoiceBindDialog,
  },
  methods: {
    addInvoiceGroup() {
      if (this.selected.length > 0) {
        this.showDialog = true;
      } else {
        alert(this.$i18n.t("invoice.alert"));
      }
    },
    onSaveEvent(selected, mode, name, group) {
      console.log(selected, mode, name, group);
      this.showDialog = false;
      if (selected.length > 0) {
        var ids = [];
        for (const [key, data] of Object.entries(selected)) {
          ids.push(data.id);
        }
        if (mode === 1) {
          const url = "/container/invoice_group/" + group + "/append/";
          this.postApi({ containers: ids }, url);
        } else {
          const url = "/container/invoice_group/";
          this.postApi({ containers: ids, name: name }, url);
        }
        
      } else {
        alert(this.$i18n.t("invoice.alert"));
      }
    },
    postPostApi(action, responseData) {
      this.$emit('refresh')
    }
  },
  watch: {
    invoice_complete(val){
      this.getParams['invoice_group__isnull'] = !val
      // this.getParams["status"] = !val?this.container_status.close:this.container_status.invoice;
      this.getApi()
    },
  },
  mounted() {
    this.getParams["invoice_group__isnull"] = true;
    this.getParams["status__in"] = [this.container_status.close,this.container_status.invoice];
  },
};
</script>